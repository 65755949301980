
input {
    box-shadow: none !important;
}

*[readonly],
*:hover[readonly] {
    border: 0;
    background-color: inherit !important;
}

#navbar-logo {
    width: 105px;
}

#navbar-logo.logo-large {
    width: 230px;
}

.inline-size-max-content {
    inline-size: max-content !important;
}

.card-master {
    background-image: url("assets/images/bg-corner.001.png");
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    background-size: auto;
}

.card-master-2 {
    background-image: url("assets/images/bg-corner.002.png");
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    background-size: auto;
}

.card-transparent {
    background: rgba(255, 255, 255, .001);
    height: 116px;
    line-height: 75px;
    box-shadow: none;
    border: dashed 1px var(--falcon-headings-color);
}

.card-opacity {
    --falcon-card-bg: rgba(255, 255, 255, .8) !important;
}

/* SWITCH */

.switch {
    --circle-dim: 1.4em;
    font-size: 14px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
}

.switch-small {
    font-size: 10px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f5aeae;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 30px;
}

.slider-card {
    position: absolute;
    content: "";
    height: var(--circle-dim);
    width: var(--circle-dim);
    border-radius: 20px;
    left: 0.3em;
    bottom: 0.3em;
    -webkit-transition: .4s;
    transition: .4s;
    pointer-events: none;
}

.slider-card-face {
    position: absolute;
    inset: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    border-radius: 50%;
    -webkit-transition: .4s transform;
    transition: .4s transform;
}

.slider-card-front {
    background-color: #DC3535;
}

.slider-card-back {
    background-color: #379237;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

input:checked ~ .slider-card .slider-card-back {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
}

input:checked ~ .slider-card .slider-card-front {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

input:checked ~ .slider-card {
    -webkit-transform: translateX(1.5em);
    -ms-transform: translateX(1.5em);
    transform: translateX(1.5em);
}

input:checked ~ .slider {
    background-color: #9ed99c;
}

input[disabled] ~ .slider {
    background-color: #303a47;
}

/* Slick */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-slider .slick-track, .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before, .slick-track:after {
    display: table;
    content: '';
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-slide.slick-current .tick {
    border: 1px solid var(--gloowe-blue);
    background-color: var(--gloowe-blue);
    box-shadow: inset 0 0 0 8px #fff;
}

.slick-slide .tick {
    width: 40px;
    height: 40px;
    background-color: #666;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 8px solid #fff;
    margin-right: 20px;
    transition: all .3s ease;
}

/* Category Content + Consumable Content */

.category-content header {
    background: var(--gloowe-blue); /*var(--brand-menu-primary);*/
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    padding: 10px 15px 15px;
}

.consumable-content header {
    cursor: pointer;
    margin-top: 20px;
}

.consumable-content header:first-of-type,
.category-content header:first-of-type {
    margin-top: 0;
}

/* Google Autocomplete */

.pac-container {
    background-color: var(--falcon-hover-bg-100);
    font-family: var(--falcon-body-font-family);
}

.pac-item,
.pac-item-query {
    color: var(--gloowe-blue);
}

.pac-item:hover {
    background: #6666;
}

.bi {
    width: 1.2em;
    height: 1.2em;
    vertical-align: -0.125em;
    fill: currentcolor;
}

.col-filter .input-group-text {
    background: transparent;
    border: none;
}

.col-filter .react-select__control {
    min-width: 250px;
}

/* Payment logo */
.card-method-payment {
    cursor: pointer !important;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--falcon-card-bg);
    background-clip: border-box;
    border: var(--falcon-card-border-width) solid var(--falcon-card-border-color);
    border-radius: var(--falcon-card-border-radius);
    box-shadow: var(--falcon-box-shadow);
    min-height: 55px;
}

.card-method-payment.active {
    border-color: var(--gloowe-blue);
    border-width: 3px;
}