.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(13,18,22,.7);
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    z-index: 9000 !important;
}