.modal {
    position: initial;
    top: initial;
    left: inherit;
    width: clamp(50%, 1000px, 90%);
    height: min(90%, 1500px);
    margin: auto;
    padding: 15px 15px 0;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal.modal-xs {
    width: 600px;
}

.modal.modal-sm {
    width: 875px;
}

.modal-header h3 {
    color: var(--dark-background-color) !important;
}

.px-btn.close {
    transition: background-color .1s linear, border-color .1s linear, color .1s linear;
    --icon-size: 16px;
    height: 32px;
    padding: 0 2px;
    border-radius: 100%;
    border: 0;
    line-height: 11px;
    z-index: 1;
}

.px-btn.close {
    position: absolute !important;
    right: 0;
    top: 0;
    max-height: 32px;
    max-width: 32px;
    min-height: 32px;
    min-width: 32px;
}

.px-btn.close {
    background-color: rgba(13,18,22,.7);
    color: #ffffff;
}

.modal-container {
    position: relative;
    display: flex;
    padding: 24px 40px;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    outline: 0;
}

.modal-button {
    position: relative;
    padding: 0 3rem;
    min-height: 3rem;
    margin: auto auto 0 auto;
    background: var(--falcon-primary);
    border: solid 1px var(--falcon-primary);
    color: var(--falcon-white);
}

.modal-form input {
    width: 50%;
    font-weight: 600;
    border: none;
}

.modal-form textarea {
    width: 100%;
    font-weight: 600;
    border-radius: 10px;
}

.modal-form input::-webkit-input-placeholder,
.modal-form input::-moz-placeholder,
.modal-form input::-moz-placeholder,
.modal-form input::-ms-input-placeholder {
    text-align: center;
}

.modal-body .details {
    border-right: solid 1px #cecece;
}

.modal-body .price-content {
    margin-top: 15px;
    position: absolute;
    bottom: 15px;
}

@media (max-width: 576px) {
    .modal {
        height: clamp(70%, 450px, 75%);
        padding: 0 15px;
    }
}

@media (max-width: 767px) {
    .modal {
        height: clamp(70%, 850px, 80%);
        padding: 15px 15px 0;
    }

    .modal .modal-container {
        padding: 15px 5px;
    }

    .modal-header {
        padding: 15px 0;
    }

    .modal-body {
        padding: 1rem 0;
    }

    .modal-body .details {
        display: none;
    }

    .modal-form input {
        width: 100%;
    }

    .modal-button {
        width: 100%;
    }
}