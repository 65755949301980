.create-container {
    position: absolute;
}

@media (max-width: 992px) {
    .create-container {
        display: block;
        width: auto !important;
        position: fixed !important;
        bottom: 69px;
        left: 15px;
        right: 15px;
    }

    .create-container .card-body:after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 1rem;
        height: 1rem;
        bottom: -0.54rem;
        left: 48%;
        transform: rotate(45deg);
        border-radius: 0.125rem;
        border-color: var(--gloowe-blue);
        background: #fff !important;
        border-style: solid;
        border-width: 0 1px 1px 0;
    }
}