
.navbar-glass-shadow-top{
    box-shadow: 0.5rem 0 0.5rem -0.5rem rgba(0, 0, 0, .2) !important;
}

.gloowe-dashboard {
    border: inherit;
}

@media (max-width: 767px) {
    .gloowe-dashboard {
        border: solid 1px var(--gloowe-blue);
        border-bottom: none;
        border-radius: 10px 10px 0 0;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        display: block;
        width: auto !important;
        position: fixed !important;
        bottom: 69px;
        left: -1px;
        right: -1px;
        background-color: var(--falcon-light);
    }

    .gloowe-dashboard .nav-item {
        justify-content: center !important;
    }

    .gloowe-dashboard .nav-item .nav-link div{
        text-align: center;
    }
}