
.css-26l3qy-menu {
    top: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 10%) 0 0 0 1px, rgb(0 0 0 / 10%) 0 4px 11px;
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
}

.css-11unzgr {
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    position: relative;
    box-sizing: border-box;
}

.css-yt9ioa-option {
    background-color: transparent;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
}

.css-1d8n9bt {
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.css-qc6sy-singleValue {
    color: rgb(51, 51, 51);
    grid-area: 1 / 1 / 2 / 3;
    margin-left: 2px;
    margin-right: 2px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}

.css-nwjfc {
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    visibility: visible;
    color: rgb(51, 51, 51);
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1 / 1 / 2 / 3;
    grid-template-columns: 0 min-content;
    box-sizing: border-box;
}

.css-1wy0on6 {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.css-1okebmr-indicatorSeparator {
    align-self: stretch;
    background-color: rgb(204, 204, 204);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
}

.css-tlfecz-indicatorContainer {
    color: rgb(204, 204, 204);
    display: flex;
    padding: 8px;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
}