.bg-auth-circle-shape {
    position: absolute;
    right: -8.75rem;
    top: -5.125rem;
}

.bg-auth-circle-shape-2 {
    position: absolute;
    left: -6.25rem;
    bottom: -2.4375rem;
}